<template>
  <div class="main-content" style="padding-top: 0px">
    <div class="box-left-logo">
      <img @click="$router.push('/')" src="@/assets/image/header/logo.png" alt="logo" style="cursor: pointer">
    </div>
    <div class="exchange">
      <img src="@/assets/image/erwei.png" alt="erwei" />
      <h3>兌換成功</h3>
    </div>
    <div class="codeBody">
      <div class="codeBodyTop">
        <!-- <div class="code1">
          <img src="@/assets/image/success.png" alt="success"/>
          <h4>兌換成功</h4>
        </div> -->
        <div class="code2">
          <!-- <p>方案名稱 : {{ obj.name }}</p>
          <p>電子郵件 : {{ obj.email }}</p>
          <p>效期30天，請使用支援eSIM的手機掃描安裝</p> -->
          <div class="code2_title">iPhone 手機</div>
          <p>
          <p>SM-DP+ 位址</p>
          <p class="code2_code"><el-input v-model="obj.phone[1]" disabled></el-input> <img src="@/assets/image/copy.png"
              alt="複製" @click="copyText(obj.phone[1])"></p>
          </p>
          <p>
          <p>啟用碼</p>
          <p class="code2_code"><el-input v-model="obj.phone[2]" disabled></el-input> <img src="@/assets/image/copy.png"
              alt="複製" @click="copyText(obj.phone[2])"></p>
          </p>
          <div class="code2_title" style="padding-top: 20px;">Android 手機</div>
          <p class="code2_code">
            <el-input v-model="obj.qrcode" disabled></el-input> <img src="@/assets/image/copy.png" alt="複製"
              @click="copyText(obj.qrCode)">
          </p>
        </div>
        <div class="code3">
          <!--          <svg v-if="!qrcode" class="ring-container" style="width: 130px; height: 130px;">-->
          <!--            <circle cx="65" cy="65" r="60" stroke="#656565" stroke-width="5" stroke-linecap="round" fill="none">-->
          <!--            </circle>-->
          <!--            <circle cx="65" cy="65" r="60" stroke-width="5" stroke="#FFC700" stroke-dasharray="376"-->
          <!--                    stroke-linecap="round"-->
          <!--                    fill="none" class="ring">-->
          <!--              <animate attributeName="stroke-dashoffset" from="376" to="0" dur="30"></animate>-->
          <!--            </circle>-->
          <!--            <text x="80" y="80" text-anchor="end" font-size="31" fill="#FFC700">{{ time }}</text>-->
          <!--            <text x="80" y="80" text-anchor="start" font-size="21" fill="#FFC700">s</text>-->
          <!--          </svg>-->
          <!--          <span v-if="!qrcode" class="word" style="white-space: nowrap;">QR code 正在生成</span>-->
          <!--          <div v-show="qrcode" :id="id" :ref="id" style="height: 100%;padding: 10px;background: #ffffff"/>-->
          <img :src="obj.qrcode_url" width="165" height="165" alt="" />
          <el-input v-model="obj.code" width="100px" disabled></el-input>
          <!--          <span v-show="qrcode">{{ obj.snPin }}</span>-->
        </div>
      </div>
      <!-- <div class="codeBodyBom">
        <span>我們將在12小時內將QR Code 發送到您的郵箱({{ obj.email }} )</span>
      </div> -->

    </div>
  </div>
</template>

<script>
  import QRCode from 'qrcodejs2'
  import { queryResult } from "@/request/api";
  import { Loading } from "element-ui";

  export default {
    name: "detaile.vue",
    data() {
      return {
        qrcode: '',
        id: 'newqrcode',
        time: 30,
        obj: {
          phone: ['', '', '']
        },
      }
    },
    mounted() {
      setInterval(() => {
        if (this.time > 0) {
          this.time--
        }
      }, 1000)

      // this.getQrcodeUrl(this.$route.query.id)
      this.obj = JSON.parse(this.$route.query.obj)
      this.$set(this.obj, 'email', this.$route.query.email)
      this.$set(this.obj, 'code', this.$route.query.code)
      this.$set(this.obj, 'phone', JSON.parse(this.$route.query.obj)?.qrcode?.split('$') || ['', '', ''])
      console.log(this.obj, '_____', this.qrcode)
      // this.createQrcode(this.obj.qrcode_url)
    },
    methods: {
      // async getQrcodeUrl(id) {
      //
      //   let res = await queryResult({snpin_id: id})
      //   if(res.code == 200){
      //     this.obj = res.data
      //     this.obj.email = this.$route.query.email
      //     this.createQrcode(this.obj.qrcode_url)
      //   } else {
      //
      //   }
      //
      // },


      createQrcode(text) {
        if (this.qrcode) {
          this.$refs[this.id].innerHTML = ''
        }
        this.qrcode = new QRCode(this.$refs[this.id], {
          text: text,
          width: 165,
          height: 165,
          colorDark: '#000000',
          colorLight: '#ffffff',
          correctLevel: QRCode.CorrectLevel.H,
          margin: 5
        })

        const logo = new Image()
        logo.setAttribute('crossOrigin', 'Anonymous')
        logo.src = require('@/assets/image/header/logo.jpg')
        logo.onload = () => {
          const qrImg = this.qrcode._el.getElementsByTagName('img')[0]
          const canvas = this.qrcode._el.getElementsByTagName('canvas')[0]
          this.qrcode._el.title = ''
          canvas.style.display = 'inline-block'
          const ctx = canvas.getContext('2d')
          ctx.drawImage(logo, (165 - 165) / 2, (165 - 165 / 4) / 2, 165, 165 / 4)
          qrImg.src = canvas.toDataURL()
          qrImg.style.display = 'none'
          this.$refs[this.id].appendChild(this.qrcode._el)
        }
      },
      copyText(text) {
        const tempInput = document.createElement('textarea');
        tempInput.value = text;
        document.body.appendChild(tempInput);
        tempInput.select();
        document.execCommand('copy');
        document.body.removeChild(tempInput);
        this.$message({
          type: 'success',
          message: '複製成功'
        })
      }
    }
  }
</script>


<style scoped lang="less">
  .exchange {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 20px;
    margin-bottom: 80px;
    margin-top: 80px;

    h3 {
      font-size: 32px;
      line-height: 44px;
      color: #ffffff;
      font-weight: bolder;
    }
  }

  .codeBody {
    padding: 80px;
    background: #1E1E1E;
    border-radius: 10px;

    .codeBodyTop {
      display: flex;
      align-items: center;
      justify-content: space-between;
      gap: 20px;

      .code1 {
        text-align: center;
        display: block;

        h4 {
          font-size: 24px;
          line-height: 35px;
          color: #ffffff;
          margin-top: 10px;
          font-weight: bolder;
          letter-spacing: 2px;
          white-space: nowrap;
        }
      }

      .code2 {
        flex: 1;

        p {
          margin: 0 0 0px;
          font-size: 18px;
          line-height: 30px;
          color: #ffffff;
        }

        .code2_title {
          color: rgba(2, 119, 255, 1);
          font-size: 24px;
          margin-bottom: 20px;
        }

        .code2_code {
          display: flex;
          align-items: center;
          gap: 10px;

          img {
            width: 20px;
            height: auto;

            &:hover {
              cursor: pointer;
            }
          }

          ::v-deep .el-input__inner {
            height: 30px;
          }
        }

        /* p:nth-child(3) {
        color: #FFC700;
      } */
      }

      .code3 {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 20px;

        span {
          display: block;
          font-size: 18px;
          line-height: 30px;
          color: #ffffff;
          text-align: center;
          margin-top: 5px;
        }

        ::v-deep .el-input {
          width: 150px;
        }
      }
    }
  }

  .codeBodyBom {
    text-align: end;
    margin-top: 60px;

    span {
      padding: 4px 17px;
      color: #ffffff;
      border-radius: 36px;
      font-size: 16px;
      background: #0B57C9;
    }
  }

  ::v-deep .el-input__inner {
    color: #000 !important;
  }

  @media screen and (max-width: 768px) {
    .exchange {
      margin-top: 40px;
      margin-bottom: 0px;

      h3 {
        font-size: 24px;
        line-height: 34px;
      }
    }

    .codeBody {
      background: transparent;
      padding: 40px 20px;

      .codeBodyTop {
        flex-wrap: wrap;

        .code1 {
          display: none;
        }

        .code2 {
          width: 100%;
          order: 2;
          text-align: center;

          p {
            font-size: 14px;
            margin-bottom: 0;
          }

          p:nth-child(3) {
            margin-top: 20px;
          }
        }

        .code3 {
          width: 100%;
          margin: 20px auto;
          order: 1;
          padding: 20px;
          background: #1E1E1E;
          border-radius: 10px;

          span {
            font-size: 16px;
          }
        }
      }

      .codeBodyBom {
        text-align: center;
        margin-top: 20px;

        span {
          display: inline-block;
          padding: 4px 17px;
          color: #ffffff;
          border-radius: 36px;
          line-height: 20px;
          font-size: 14px;
          background: #0B57C9;
          white-space: pre-wrap;
        }
      }
    }
  }
</style>